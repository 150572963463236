<template>
  <div class="container">
    <h1>AVISO DE PRIVACIDAD INTEGRAL</h1>
    <p>
      WALLEAT.COM, S.A. DE C.V. (“WALLEAT” o el “responsable”) pone a tu
      disposición (el “Titular” o “Tú”) el presente aviso de privacidad integral
      (el “Aviso de Privacidad”) en cumplimiento a lo previsto en la Ley Federal de
      Protección de Datos Personales en Posesión de los Particulares (la “Ley” o
      “LFPDPPP”), su Reglamento y los Lineamientos del Aviso de Privacidad. La
      obtención, uso, divulgación o almacenamiento (el “Tratamiento”) se hará a
      través de documentación física y electrónica (la “Documentación”) de la
      información que te identifique o haga identificable (tus “Datos Personales”).
    </p>
    <h3>I. DOMICILIO DEL RESPONSABLE</h3>
    <p>
      WALLEAT.COM, S.A. DE C.V., señala como domicilio para oír y recibir
      notificaciones el ubicado en calle Z. Fajardo Luna 30, C.P. 89820, Cd Mante,
      Tamaulipas, México.
    </p>
    <h3>II.	DATOS PERSONALES QUE SERÁN TRATADOS</h3>
    <p>
      Se obtendrán, directa o indirectamente, Datos Personales de las siguientes categorías: 
      (a) Datos de identificación, entre los que destacan: (1) Apellido paterno, apellido materno y nombre(s) y/o nombre del negocio, (2) Fecha de nacimiento, (3) Municipio, Estado y país de nacimiento, (4) Nacionalidad, (5) Domicilio particular, (6) Número de teléfono – en que se pueda localizar -, (7) Correo electrónico, (8) Clave única de registro de población y/o la clave del registro federal de contribuyentes (con homoclave) - cuando disponga de ellos, (9) Giro del negocio, (10) el historial de compras/ventas del titular de los datos, (11) Sexo (en el caso de personas físicas).
      (b) Datos de contacto (cuenta de Facebook, Instagram).  
      (c) Datos de localización (dirección). 
      (d) Datos fiscales. 
      WALLEAT no solicitará ni realizará tratamiento de otro tipo de datos personales o personales sensibles, entendiéndose por estos, todos aquellos Datos Personales que afecten la esfera más íntima de su Titular, o cuya utilización indebida pudiera dar origen a discriminación o conllevase un riesgo grave para éste.

    </p>

    <h3>III.	FINALIDADES DEL TRATAMIENTO</h3>
    <p>
      Finalidades primarias que dan origen y son necesarias para tu relación con WALLEAT:
      (a) Para identificarte; 
      (b) Para comunicarnos contigo; 
      (c) Para que puedas tener una navegación óptima dentro de nuestra plataforma. 
      (d) Para que puedas realizar compras por medio de nuestra plataforma. 
      (e) Para verificar las compras que realices por medio de nuestra plataforma.
      (f) Para servir como intermediario entre las compras y/o ventas que realices a través de nuestra plataforma.
      (g) Para que podamos emitirte la factura correspondiente por tus compras. 
      (h) Para realizarte sugerencias para tus próximas compras. 

      Finalidades secundarias:
      (a) Mercadotecnia, publicidad o prospección comercial con relación a productos y/o servicios de WALLEAT.  
      (b) Comunicación para fines publicitarios, y:
      (c) La invitación a futuros eventos organizados por WALLEAT y/o socios comerciales.
    </p>

    <h3>IV.	TRANSFERENCIAS DE DATOS PERSONALES QUE SE EFECTÚEN</h3>
    <p>
      Para cumplir con las obligaciones jurídicas que corresponden a WALLEAT y para el cumplimiento de las finalidades principales y secundarias expresadas en el presente Aviso de Privacidad, WALLEAT no transferirá su información a ningún tercero.Lo anterior con independencia de las transferencias que no requieren de tu consentimiento conforme a lo dispuesto en el artículo 37 de la LFPDPPP. Cualquier otra transferencia de datos personales sólo será llevada a cabo con tu consentimiento y previa información al respecto. Cuando el consentimiento para la transferencia de datos personales sea legalmente exigible, solicitaremos tu consentimiento expreso y por escrito de forma previa a la realización de cualquier transferencia. El consentimiento antes mencionado se podrá obtener por cualquier medio físico o electrónico que acredite su otorgamiento, tales como: formularios, formatos de servicio, órdenes o cartas de autorización.

    </p>

    <h3>V.	MEDIOS Y PROCEDIMIENTOS PARA EJERCITAR LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN Y OPOSICIÓN</h3>
    <p>
      Tienes derecho para Acceder a, Rectificar y/o Cancelar tus Datos Personales, así como para oponerte al Tratamiento de estos (“Derechos ARCO”). Para ejercerlos deberás enviar por correo electrónico dirigido a Atención a clientes a la dirección de correo electrónico contacto@mywalleat.com, el formato de solicitud para el ejercicio de Derechos ARCO que podrás descargar aquí (https://image.bebidas.fifco.com/lib/fe3911717064047d741175/m/1/d5e8f813-fc81-4450-8648-7c9f863bbc0e.pdf) indicando claramente: (a) el derecho que deseas ejercitar; (b) el medio por el cual deseas recibir respuesta de WALLEAT, en el entendido que tratándose de una dirección de correo electrónico, ésta deberá corresponder con la que tuviéramos en nuestros registros; (c) indicación puntual de los Datos Personales a los que se refiera tu solicitud, y (d) cualquier información adicional que pueda facilitar la ubicación de tus Datos Personales.
      Deberás anexar a tu solicitud: (i) copia simple de una identificación oficial con fotografía y firma, que deberás firmar al calce de forma que corresponda razonablemente con la firma que conste en dicha identificación, así como de la de tu representante, en su caso; carta poder o documento que acredite la personalidad de tu representante legal, en su caso, además de (ii) la documentación comprobatoria que fundamente la modificación solicitada, en el caso de solicitudes para el ejercicio del derecho de Rectificación.
 
      En caso de que tu solicitud haya sido enviada en viernes o día inhábil, será considerada como recibida a partir del día hábil inmediato siguiente. Si tu solicitud no cumpliera con los requisitos necesarios, serás requerido para subsanarla dentro de los 5 (cinco) días hábiles siguientes a la recepción de esta, y gozarás de un término de 10 (diez) días hábiles para cumplir tal requerimiento. De no cumplirlo en ese plazo, la misma se tendrá por no presentada. Tu solicitud será respondida en un plazo no mayor a 20 (veinte) días hábiles a través del medio que hubieras indicado en tu solicitud. En caso de que sea procedente de conformidad con la Ley y demás normatividad vigente y aplicable, se te otorgará el acceso; se rectificarán o cancelarán tus datos, previo su bloqueo en los casos que corresponda; o se hará efectivo tu derecho de oposición para el tratamiento de tus datos personales. 
 
      La respuesta a tu solicitud será gratuita, siempre y cuando sea procedente y se lleve a cabo a través de una dirección de correo electrónico. En caso de que requieras que la información te sea entregada de modo diverso, deberás cubrir únicamente los gastos administrativos de recuperación que sean cotizados por el costo razonable de la elaboración de los materiales para la respuesta y los de envío por la modalidad que hubieras solicitado.

    </p>

    <h3>VI.	OPCIONES Y MEDIOS PARA LIMITAR EL TRATAMIENTO DE LOS DATOS PERSONALES, ASÍ COMO PARA LA NEGATIVA AL MISMO O REVOCACIÓN DEL CONSENTIMIENTO </h3>
    <p>
      La solicitud para limitar el Tratamiento de tus Datos Personales únicamente procede a través de los medios indicados en la Sección V del presente Aviso, y respecto de finalidades que fueran secundarias en tu relación con WALLEAT, y siempre por causa legítima o para evitarte perjuicios, tienes un plazo de cinco (5) días hábiles posteriores a la fecha en que se obtuvieron tus datos personales, para que, de ser el caso, te opongas al tratamiento de los mismos para aquellas finalidades que no son necesarias, ni hayan dado origen a la relación jurídica con WALLEAT, o revocar el consentimiento que para tal fin nos hayas otorgado, en todos aquellos casos en que dicha revocación no suponga la imposibilidad de cumplir con las obligaciones jurídicas que correspondieran a WALLEAT. Tratándose de la realización de una promoción la mercadotecnia y prospección comercial de productos o servicios de WALLEAT y/o de terceros resulta necesaria; sin embargo, podrás formular tu solicitud con los requisitos de identificación para el ejercicio de los Derechos ARCO, señalando las finalidades que juzgues no sean necesarias y las causas legítimas de procedencia o los perjuicios que consideras se te evitarían. Se evaluará la solicitud y, en caso de ser aprobada, serás incluido en una lista de exclusión de WALLEAT.
      Podrás negarte al Tratamiento de cualesquiera de los Datos Personales mencionados en este Aviso de Privacidad omitiéndolos al momento en que te fueran solicitados. La manifestación de tu negativa u oposición como Titular de los Datos Personales a ser Tratados con motivo del presente podría afectar el cumplimiento de algunas de nuestras obligaciones, por lo que asumes el resultado de lo anterior.
 
      También tienes la facultad de revocar tu consentimiento al Tratamiento de tus Datos Personales, sin que dicha revocación surta efectos retroactivamente, siguiendo el procedimiento para una solicitud para el ejercicio del Derecho de Oposición indicando que se trata de una revocación.

    </p>

    <h3>VII. DERECHO DE INFORMACIÓN DE LOS INTERESADOS </h3>
    <p>
      Como titular de los Datos Personales se te informa sobre tu derecho para acudir al Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales en caso de considerar que tu derecho a la protección de datos personales ha sido vulnerado.
      Corresponde al Responsable del tratamiento de datos personales, facilitar la información a las personas afectadas por las operaciones de tratamiento, en el momento en que tenga conocimiento del daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado de los datos personales de la persona que corresponda, para lo cual el Responsable deberá adoptar y tomar las medidas necesarias de seguridad, administrativas, técnicas y físicas, con el fin de proteger y resguardar la información. 

      LISTADOS DE EXCLUSIÓN: Puedes inscribirte ante el Registro Público para Evitar Publicidad (REPEP) para limitar el uso y divulgación de tus datos personales, puedes obtener más información en el sitio: https://repep.profeco.gob.mx/

    </p>

    <h3>VIII.	PROCEDIMIENTOS Y MEDIOS PARA COMUNICAR LOS CAMBIOS EN EL AVISO DE PRIVACIDAD </h3>
    <p>
      WALLEAT podrá modificar, actualizar, extender o de cualquier otra forma cambiar el contenido y alcance del presente Aviso de Privacidad, en cualquier momento y bajo su completa discreción. Las modificaciones podrán hacerse de tu conocimiento a través de los medios de comunicación que nos hubieras indicado, o bien, por campañas de difusión vía correo electrónico. Deberás estar atento a la información sobre la versión vigente de este Aviso de Privacidad por los medios disponibles. 
      CONSENTIMIENTO AL AVISO DE PRIVACIDAD. Por la naturaleza de los Datos Personales a los que se dará Tratamiento basta con que no manifiestes oposición al presente Aviso de Privacidad para contar con tu consentimiento tácito sobre los términos de este documento.
      Fecha última actualización 15 de abril del 2021.
    </p>
    <h4>Acepto que he leído el Aviso de Privacidad Integral de WALLEAT y autorizo el tratamiento de mis datos personales</h4>
  </div>
</template>

<script>
export default {
  data() {
    return {
      consent: false,
    };
  },
};
</script>

<style>
.container {
  max-width: 800px;
  margin: 0 auto;
}
h1, h2 {
  margin-top: 20px;
}
</style>